import { useCallback } from 'react';
import { useNavigate } from '../../rewrite/navigation/hooks/useNavigate';
import { useCurrentViewId } from '../hooks/useCurrentViewId';
import { trackOpenSavedView } from '../../tracking/indexPageTracking';
import { useViewActions } from './useViewActions';
export const useOpenViewById = () => {
  const {
    optimisticallyUpdateCurrentViewId
  } = useViewActions();
  const currentViewId = useCurrentViewId();
  const navigate = useNavigate();
  return useCallback(id => {
    if (id === currentViewId) {
      return;
    }
    optimisticallyUpdateCurrentViewId(id);
    navigate({
      viewId: id
    });
    trackOpenSavedView({
      action: 'from pin'
    });
  }, [currentViewId, navigate, optimisticallyUpdateCurrentViewId]);
};