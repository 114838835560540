import get from 'transmute/get';
import { useTableQueryCache } from '../../table/hooks/useTableQueryCache';

/**
 * Gets the current filtered view object count, or undefined if the query cache hasn't been populated yet.
 * @returns {number} view object count
 */
export const useViewObjectCount = () => {
  const {
    data
  } = useTableQueryCache();

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(CrmIndexSearchQuery & { crmObje... Remove this comment to see the full error message
  return get('total', data);
};