import { useCallback, useEffect } from 'react';
import { useTableQueryAsync } from '../../table/hooks/useTableQuery';
import { useFlywheelDealCSATSurvey } from '../../../surveys/hooks/flywheelSurvey';
import { priorityLoaderExec } from '../../../loadable/lib/priorityLoader';
import { useOpenDealPreviewSidebarOnLoad } from 'commerce-tours-lib/tours/convertDealToTool/hooks';
import Raven from 'raven-js';
import { useSelectedObjectTypeDef } from '../../../crmObjects/hooks/useSelectedObjectTypeDef';
export const useAfterSuccessActions = () => {
  const fetch = useTableQueryAsync();
  const triggerFlywheelDealCSATSurvey = useFlywheelDealCSATSurvey();
  const typeDef = useSelectedObjectTypeDef();
  const openDealPreviewSidebarOnLoad = useOpenDealPreviewSidebarOnLoad((typeDef === null || typeDef === void 0 ? void 0 : typeDef.objectTypeId) || '');
  const execute = useCallback(() => {
    triggerFlywheelDealCSATSurvey();
    fetch();
    openDealPreviewSidebarOnLoad();
  }, [fetch, openDealPreviewSidebarOnLoad, triggerFlywheelDealCSATSurvey]);
  useEffect(() => {
    priorityLoaderExec({
      loader: execute,
      priority: 'low'
    }).catch(err => {
      Raven.captureException(err);
    });
  }, [execute]);
};