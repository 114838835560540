export function setSpeculations() {
  if (!HTMLScriptElement.supports || !HTMLScriptElement.supports('speculationrules')) {
    return;
  }
  const scriptEl = document.createElement('script');
  scriptEl.type = 'speculationrules';
  const specRules = {
    prerender: [{
      where: {
        selector_matches: "a[href*='contacts/'][href*='/record/']"
      },
      eagerness: 'moderate'
    }]
  };
  scriptEl.textContent = JSON.stringify(specRules);
  document.body.append(scriptEl);
}