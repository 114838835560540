import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useDataFetchingClient } from 'data-fetching-client';
import { fetchViewById as fetchViewByIdAPI } from '../api/viewsAPI';
import { useSelectedObjectTypeId } from '../../objectTypeIdContext/hooks/useSelectedObjectTypeId';
import { useCallback } from 'react';
const fetchViewById = registerQuery({
  fieldName: 'view',
  args: ['viewId', 'objectTypeId'],
  fetcher: fetchViewByIdAPI
});
export const useLazyFetchView = () => {
  const objectTypeId = useSelectedObjectTypeId();
  const client = useDataFetchingClient();
  return useCallback(viewId => {
    if (!viewId) {
      return Promise.resolve({
        data: undefined,
        loading: false,
        errors: undefined
      });
    }
    return client.query({
      query: fetchViewById,
      variables: {
        viewId,
        objectTypeId
      }
    }).then(_ref => {
      let {
          data
        } = _ref,
        rest = _objectWithoutPropertiesLoose(_ref, _excluded);
      return Object.assign({
        data: data && data.view
      }, rest);
    });
  }, [client, objectTypeId]);
};