import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import set from 'transmute/set';
import hasIn from 'transmute/hasIn';
import { filterGroupsChangedAction, quickFiltersChangedAction, columnsChangedAction, sortChangedAction, resetViewAction, saveViewAction, createNewViewAction, optimisticallyUpdateCurrentViewIdAction, viewPipelineChangedAction, quickFilterPropertiesChangedAction, datawellReportIdUpdatedAction } from '../actions/viewsActions';
import { useStoreViewsActions } from './useStoreViewsActions';
import { useCurrentView } from './useCurrentView';
import { useCurrentViewId } from './useCurrentViewId';
import { deleteGridState, updateGridState } from '../../crm_ui/grid/utils/gridStateLocalStorage';
import { trackEditSavedView, trackEditSavedViewRedesign, trackCreateView, trackSortChanged, trackResetViewRedesign } from '../../tracking/indexPageTracking';
import { useSelectedObjectTypeDef } from '../../crmObjects/hooks/useSelectedObjectTypeDef';
import { useIsVisibleGridColumnName } from './useIsVisibleGridColumnName';
import { applyColumnRules } from '../utils/applyColumnRules';
import { useIsVisibleFilterPropertyName } from '../../rewrite/properties/hooks/useIsVisibleFilterPropertyName';
import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { isGenericAssociation } from 'customer-data-objects/associations/isGenericAssociation';
import InboundDbListMembershipProperty from 'customer-data-objects/property/special/InboundDbListMembershipProperty';
import ListMembershipsProperty from 'customer-data-objects/property/special/ListMembershipsProperty';
import { getDoesViewExist, putViewConfiguration } from '../api/viewsAPI';
import debounce from 'transmute/debounce';
import { unique } from '../../utils/unique';
import { useIsCurrentViewModified } from './useIsCurrentViewModified';
import { isHubspotStandardView } from 'customer-data-views-management/singleView/dataOperators/isHubspotStandardView';
import Raven from 'raven-js';
import { generateUpdatedViewConfiguration } from '../utils/datawellReportingViewUtils';
const debounceCheckIfViewExists = debounce(300, (name, onSuccess, onFailure, objectTypeId) => {
  getDoesViewExist({
    objectTypeId,
    name
  }).then(onSuccess).catch(onFailure);
});
export const useViewActions = () => {
  const dispatch = useDispatch();
  const typeDef = useSelectedObjectTypeDef();
  const {
    objectTypeId,
    primaryDisplayLabelPropertyName
  } = typeDef;
  const currentView = useCurrentView();
  const {
    columns
  } = currentView;
  const viewId = useCurrentViewId();
  const isDefault = isHubspotStandardView({
    view: currentView
  });
  const checkIfViewExists = useCallback((name, onSuccess, onFailure) => debounceCheckIfViewExists(name, onSuccess, onFailure, objectTypeId), [objectTypeId]);
  const resetCurrentViewRedesign = useCallback(() => {
    trackResetViewRedesign();
    dispatch(resetViewAction({
      objectTypeId,
      viewId
    }));
    deleteGridState({
      objectType: objectTypeId,
      viewId
    });
  }, [dispatch, objectTypeId, viewId]);
  const isViewModified = useIsCurrentViewModified();
  const saveView = useCallback(({
    view,
    clearSessionStorage = true
  }) => {
    trackEditSavedView({
      isViewModified
    });
    return dispatch(saveViewAction({
      objectTypeId,
      view
    })).then(() => {
      if (clearSessionStorage) {
        deleteGridState({
          objectType: objectTypeId,
          viewId: view.id
        });
      }
    }).catch();
  }, [dispatch, isViewModified, objectTypeId]);
  const saveViewRedesign = useCallback(({
    view,
    clearSessionStorage = true
  }) => {
    trackEditSavedViewRedesign({
      isViewModified
    });
    return dispatch(saveViewAction({
      objectTypeId,
      view
    })).then(() => {
      if (clearSessionStorage) {
        deleteGridState({
          objectType: objectTypeId,
          viewId: view.id
        });
      }
    }).catch();
  }, [dispatch, isViewModified, objectTypeId]);
  const saveCurrentViewRedesign = useCallback(() => saveViewRedesign({
    view: currentView
  }), [currentView, saveViewRedesign]);
  const onDatawellReportUpdated = useCallback(async ({
    reportId
  }) => {
    const updateViewConfigurationResult = generateUpdatedViewConfiguration(currentView, reportId);
    if (updateViewConfigurationResult.type === 'reportIdUpdated') {
      await putViewConfiguration({
        objectTypeId,
        viewId: currentView.id,
        viewConfiguration: updateViewConfigurationResult.updatedConfiguration
      });
      dispatch(datawellReportIdUpdatedAction({
        objectTypeId,
        viewId: currentView.id,
        viewConfiguration: updateViewConfigurationResult.updatedConfiguration
      }));
    }
  }, [currentView, objectTypeId, dispatch]);
  const {
    replaceViews
  } = useStoreViewsActions();
  const createView = useCallback(({
    view,
    isClone = false,
    isSaveViewDropdown = false
  }) => {
    trackCreateView({
      isClone,
      isSaveViewDropdown
    });
    return dispatch(createNewViewAction({
      objectTypeId,
      view
    })).then(createdView => {
      replaceViews({
        [createdView.id]: createdView
      });
      return createdView;
    });
  }, [dispatch, objectTypeId, replaceViews]);
  const isVisibleGridColumnName = useIsVisibleGridColumnName();
  const onColumnsChanged = useCallback(({
    columns: newColumns,
    isFromQueryParam = false,
    state: newState
  }) => {
    const columnsToUse = isFromQueryParam ? newColumns : applyColumnRules({
      columns: newColumns,
      primaryDisplayLabelPropertyName,
      isVisibleGridColumnName
    });
    dispatch(columnsChangedAction(Object.assign({
      objectTypeId,
      viewId,
      //@ts-expect-error columnsToUse type should match that of newColumns
      columns: columnsToUse
    }, newState && {
      state: newState
    })));

    // Caching all types of view's column changes is necessary here to handle the edge case
    // when users change a view's columns but navigate away before the save happens (it's on a debounce)
    updateGridState({
      objectType: objectTypeId,
      viewId,
      key: 'columns',
      value: columnsToUse
    });
    if (isDefault && !isFromQueryParam) {
      const newView = set('columns', columnsToUse, currentView);
      saveView({
        view: Object.assign({}, newView, newState && {
          state: newState
        }),
        clearSessionStorage: false
      }).catch(e => Raven.captureException(e));
    }
  }, [currentView, dispatch, isDefault, isVisibleGridColumnName, objectTypeId, primaryDisplayLabelPropertyName, saveView, viewId]);
  const isVisibleFilterPropertyName = useIsVisibleFilterPropertyName();
  const onFiltersChanged = useCallback(({
    nextFilters,
    isFromFiltersQueryParam = false
  }) => {
    // @ts-expect-error filters array is untyped.
    const validateFilters = filtersArray =>
    // We allow anything from the ?filters query param to avoid breaking anyone's existing links using this feature.
    isFromFiltersQueryParam ? filtersArray : filtersArray.filter(
    // @ts-expect-error untyped
    ({
      property
    }) => property === 'associations.labeled' || isVisibleFilterPropertyName(property) ||
    // HACK: We want to allow generic association filters through to support the record's "view all x objects" feature.
    // Once we officially support these filters we can remove this hack and allow them through isVisibleFilterPropertyName.
    isGenericAssociation(property) ||
    // HACK: The salesforce integration generates ILS lists to power their sync errors experience. They then
    // link to a view filtered by that list, which flows through this code for validation. For now we'll bypass
    // the existing validation just for contacts to allow those filters to work, but once the ILS migration is
    // complete we should remove this bypass asap in favor of just isVisibleFilterPropertyName.
    // We're also bypassing the check on the old list membership property here because during the ILS migration
    // it's possible for both values to be present so we always want to consider them valid filters. Similar to
    // the case for the ILS list membership property, this case can be removed when the ILS migration is done.
    objectTypeId === CONTACT_TYPE_ID && (property === InboundDbListMembershipProperty.name || property === ListMembershipsProperty.name));
    if (nextFilters.length === 0) {
      dispatch(filterGroupsChangedAction({
        objectTypeId,
        viewId,
        filterGroups: nextFilters
      }));
      updateGridState({
        objectType: objectTypeId,
        viewId,
        key: 'filterGroups',
        value: nextFilters
      });
      return;
    }

    // If the first entry in the "filters" array itself has a "filters" field,
    // that means it is an array of filter groups, not a filter group itself.
    const isInFilterGroupsFormat = hasIn([0, 'filters'], nextFilters);
    const filterGroups = isInFilterGroupsFormat ? nextFilters.map(filterGroup => {
      const validatedFilters = validateFilters(filterGroup.filters);
      return Object.assign({}, filterGroup, {
        filters: validatedFilters
      });
    }) : [{
      filters: validateFilters(nextFilters)
    }];
    const filterProperties = unique(filterGroups.flatMap(filterGroup =>
    // @ts-expect-error wrong type
    filterGroup.filters.map(({
      property
    }) => property)));
    const columnProperties = new Set(columns.map(({
      name
    }) => name));
    const propsToAddToColumns = filterProperties.filter(propertyName => !columnProperties.has(propertyName)).map(name => ({
      name
    }));
    if (propsToAddToColumns.length) {
      onColumnsChanged({
        columns: columns.concat(propsToAddToColumns),
        isFromQueryParam: isFromFiltersQueryParam
      });
    }
    dispatch(filterGroupsChangedAction({
      objectTypeId,
      viewId,
      filterGroups
    }));
    updateGridState({
      objectType: objectTypeId,
      viewId,
      key: 'filterGroups',
      value: filterGroups
    });
  }, [columns, dispatch, isVisibleFilterPropertyName, objectTypeId, onColumnsChanged, viewId]);
  const onQuickFiltersChanged = useCallback(({
    nextFilters
  }) => {
    dispatch(quickFiltersChangedAction({
      objectTypeId,
      viewId,
      quickFilters: nextFilters
    }));
    updateGridState({
      objectType: objectTypeId,
      viewId,
      key: 'quickFilters',
      value: nextFilters
    });
  }, [dispatch, objectTypeId, viewId]);
  const onQuickFilterPropertiesChanged = useCallback(({
    quickFilterProperties
  }) => {
    dispatch(quickFilterPropertiesChangedAction({
      objectTypeId,
      viewId,
      quickFilterProperties
    }));
    updateGridState({
      objectType: objectTypeId,
      viewId,
      key: 'quickFilterProperties',
      value: quickFilterProperties
    });
  }, [dispatch, objectTypeId, viewId]);
  const onSortChanged = useCallback(({
    sortKey,
    sortColumnName,
    direction: order
  }) => {
    dispatch(sortChangedAction({
      sortKey,
      sortColumnName,
      order,
      objectTypeId,
      viewId
    }));
    trackSortChanged(sortKey, order, objectTypeId);
    updateGridState({
      objectType: objectTypeId,
      viewId,
      key: 'state',
      value: {
        sortColumnName,
        sortKey,
        order
      }
    });
  }, [dispatch, objectTypeId, viewId]);
  const optimisticallyUpdateCurrentViewId = useCallback(id => {
    return dispatch(optimisticallyUpdateCurrentViewIdAction({
      viewId: id
    }));
  }, [dispatch]);
  const onViewPipelineChanged = useCallback(({
    pipelineId
  }) => {
    return dispatch(viewPipelineChangedAction({
      pipelineId
    }));
  }, [dispatch]);
  return {
    onDatawellReportUpdated,
    onFiltersChanged,
    onQuickFiltersChanged,
    onQuickFilterPropertiesChanged,
    onColumnsChanged,
    onSortChanged,
    checkIfViewExists,
    saveView,
    saveCurrentViewRedesign,
    createView,
    resetCurrentViewRedesign,
    optimisticallyUpdateCurrentViewId,
    onViewPipelineChanged
  };
};