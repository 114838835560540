import { useCallback } from 'react';
import { useLazyFetchView } from '../../views/hooks/useLazyFetchView';
import { useOpenViewById } from '../../views/hooks/useOpenViewById';
import Raven from 'raven-js';
import emptyFunction from 'react-utils/emptyFunction';
import { useStoreViewsActions } from '../../views/hooks/useStoreViewsActions';
import { useViews } from '../../views/hooks/useViews';
export const useHandleViewIdChange = () => {
  const openViewById = useOpenViewById();
  const views = useViews();
  const fetchViewById = useLazyFetchView();
  const {
    storeViews
  } = useStoreViewsActions();

  // On view change, we'll hopefully have the view cached in redux because we
  // fetched pinned views. However, for a new view, we'll need to fetch it
  // first and store it before navigating to avoid a full screen flicker.
  const handleViewIdChange = useCallback(viewId => {
    if (views[viewId]) {
      openViewById(viewId);
      return;
    }
    fetchViewById(viewId).then(({
      data
    }) => {
      if (data) {
        storeViews({
          [data.id]: data
        });
      }
    })
    // We'll eat the error and navigate anyway
    .catch(emptyFunction).then(() => openViewById(viewId)).catch(err => Raven.captureException(err, {
      extra: {
        viewId,
        isInPinnedViewsBar: true
      }
    }));
  }, [fetchViewById, openViewById, storeViews, views]);
  return {
    handleViewIdChange
  };
};